import React from "react";

function Blogs(){
    return(
        <div className="container">
            <h1 className="my-100">Blogs Page</h1>
        </div>
    )
}

export default Blogs;