/** @format */

import React from "react";
function Product() {
  return (
    <div className="container">
        <h1 className="my-100">Product Page</h1>
    </div>
  );
}

export default Product;
