import React from "react";

function AboutUs(){
    return(
        <div className="container">
            <h1 className="my-100">About Us Page</h1>
        </div>
    )
}

export default AboutUs;